// App.tsx
import React from 'react';
import './output.css';
import { MainPage } from './Pages';


const App: React.FC = () => {


  return (
    <MainPage/>
  );
};

export default App;
